import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { CountryCodeResponseDto } from "@/models/interface/country-code/CountryCodeResponseDto.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class CountryCodeService extends HttpClient {
  public constructor() {
    super();
  }

  public listCountryCodes(
    params: RequestQueryParamsModel
  ): Promise<Pagination<CountryCodeResponseDto>> {
    return this.get<Pagination<CountryCodeResponseDto>>(Api.CountryCode, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const countryCodeService = new CountryCodeService();
