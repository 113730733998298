import FormBasicInfo from "./FormBasicInfo.vue";
import FormTable from "./FormTable.vue";
import ModalAssetLine from "./ModalAssetLine.vue";
import SelectAvailPo from "./SelectAvailPo.vue";
import SelectProductByType from "./SelectProductByType.vue";

export {
  FormBasicInfo,
  FormTable,
  ModalAssetLine,
  SelectAvailPo,
  SelectProductByType,
};
