

















import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { CountryCodeResponseDto } from "@/models/interface/country-code/CountryCodeResponseDto.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { countryCodeService } from "@/services/country-code.service";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectCountryCode extends Vue {
  @Prop({ required: false, default: undefined })
  value!: string | LabelInValue | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  labelInValue!: boolean;

  options: Option<CountryCodeResponseDto>[] = [];

  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions(new RequestQueryParams());
  }

  fetchOptions(params: RequestQueryParamsModel): void {
    this.loading = true;
    countryCodeService
      .listCountryCodes(params)
      .then(({ data }) => {
        this.options = data.map(e => ({
          key: e.id,
          value: e.id,
          label: `${e.code} - ${e.codeDescription}`,
          meta: e,
        }));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string | LabelInValue): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    params.search = [
      builder.push(["code", val], { like: "both" }).build(),
      builder.push(["codeDescription", val], { like: "both" }).build(),
    ].join(SearchBuilder.OR);

    this.fetchOptions(params);
  }

  findOption(
    value?: string | LabelInValue
  ): Option<CountryCodeResponseDto> | undefined {
    if (!value) {
      return;
    }

    if (typeof value === "string") {
      return this.options.find(e => value === e.value);
    } else {
      return this.options.find(e => value.key === e.key);
    }
  }
}
