var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-model",
    {
      ref: "form",
      attrs: {
        model: _vm.formModel,
        rules: _vm.formRules,
        "label-align": "left",
        "wrapper-col": { span: 12 },
        "label-col": { span: 9 }
      }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              !!_vm.formModel.checklistNumber
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_checklist_number") } },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formModel.checklistNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "checklistNumber", $$v)
                          },
                          expression: "formModel.checklistNumber"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_purchase_order_number"),
                    prop: "purchaseOrderNumber"
                  }
                },
                [
                  _c("SelectAvailPo", {
                    attrs: { disabled: !!_vm.detail.status },
                    on: { "update:meta": _vm.onChangePo },
                    model: {
                      value: _vm.formModel.purchaseOrderNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.formModel, "purchaseOrderNumber", $$v)
                      },
                      expression: "formModel.purchaseOrderNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_supplier_name"),
                    prop: "supplier"
                  }
                },
                [
                  _c("SelectSupplier", {
                    attrs: { disabled: !!_vm.detail.status },
                    on: { "update:meta": _vm.onChangeSupplier },
                    model: {
                      value: _vm.formModel.supplier,
                      callback: function($$v) {
                        _vm.$set(_vm.formModel, "supplier", $$v)
                      },
                      expression: "formModel.supplier"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_branch"),
                    prop: "branchWarehouse"
                  }
                },
                [
                  _c("SelectBranch", {
                    attrs: { disabled: !!_vm.detail.status },
                    on: { "update:meta": _vm.onChangeBranch },
                    model: {
                      value: _vm.formModel.branchWarehouse,
                      callback: function($$v) {
                        _vm.$set(_vm.formModel, "branchWarehouse", $$v)
                      },
                      expression: "formModel.branchWarehouse"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_receive_date"),
                    prop: "receiveDate"
                  }
                },
                [
                  _c("a-date-picker", {
                    staticClass: "w-100",
                    attrs: {
                      format: _vm.DATE_TIME_HOURS_DEFAULT_FORMAT,
                      "show-time": "",
                      "disabled-date": _vm.receiveDateRules,
                      disabled: !!_vm.detail.status
                    },
                    model: {
                      value: _vm.formModel.receiveDate,
                      callback: function($$v) {
                        _vm.$set(_vm.formModel, "receiveDate", $$v)
                      },
                      expression: "formModel.receiveDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_supplier_do_number"),
                    prop: "supplierDeliveryOrderNo",
                    "label-align": "right"
                  }
                },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.formModel.supplierDeliveryOrderNo,
                      callback: function($$v) {
                        _vm.$set(_vm.formModel, "supplierDeliveryOrderNo", $$v)
                      },
                      expression: "formModel.supplierDeliveryOrderNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_supplier_bill_address"),
                    prop: "supplierBillToAddress",
                    "label-align": "right"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "allow-clear": "",
                        "show-search": "",
                        "dropdown-match-select-width": false,
                        "option-filter-prop": "children",
                        "filter-option": _vm.useLocalFilter
                      },
                      model: {
                        value: _vm.formModel.supplierBillToAddress,
                        callback: function($$v) {
                          _vm.$set(_vm.formModel, "supplierBillToAddress", $$v)
                        },
                        expression: "formModel.supplierBillToAddress"
                      }
                    },
                    _vm._l(_vm.billAddressOptions, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.key, attrs: { value: item.value } },
                        [
                          _c("a-tooltip", [
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [_vm._v(" " + _vm._s(item.label || "-") + " ")]
                            ),
                            _vm._v(" " + _vm._s(item.label || "-") + " ")
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              !!_vm.formModel.createdBy
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_created_by"),
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formModel.createdBy,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "createdBy", $$v)
                          },
                          expression: "formModel.createdBy"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !!_vm.formModel.status
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_status")
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formModel.status,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "status", $$v)
                          },
                          expression: "formModel.status"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_description"),
                    prop: "description",
                    "label-align": "right"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "extra",
                      fn: function() {
                        return [
                          _c("character-length", {
                            attrs: { value: _vm.formModel.description }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("a-textarea", {
                    attrs: { disabled: !!_vm.detail.status },
                    model: {
                      value: _vm.formModel.description,
                      callback: function($$v) {
                        _vm.$set(_vm.formModel, "description", $$v)
                      },
                      expression: "formModel.description"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }