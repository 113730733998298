
























import { toTitlecase, trimSpaceToUnderscore } from "@/helpers/common";
import { useFindMasterType } from "@/hooks/master-type";
import { Option } from "@/models/class/option.class";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectBuyerType extends Vue {
  @Prop({ required: false, type: [String, Array], default: undefined })
  value!: string;

  @Prop({ required: false, type: String, default: undefined })
  mode!: string;

  @Prop({ required: false })
  placeholder!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option[] = [];
  loading = false;

  created(): void {
    this.fetchData();
  }

  emits(e: string | string[]): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  fetchData(): void {
    const toTitleCaseValues = ["PASSPORT", "OTHER"];

    this.loading = true;
    useFindMasterType("BUYER_TYPE")
      .then(res => {
        this.options = res.map<Option>(el => ({
          label: toTitleCaseValues.includes(el.value)
            ? toTitlecase(el.value)
            : el.value,
          value: trimSpaceToUnderscore(el.value),
          key: el.id,
        }));
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
