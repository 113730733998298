
























































































































import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectSupplier from "@/components/custom/select/SelectSupplier.vue";
import {
  useContactData,
  useDisabledFromTomorrow,
  useLocalFilter,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { MAX_LENGTH_CHARACTER } from "@/models/constant/global.constant";
import { DATE_TIME_HOURS_DEFAULT_FORMAT } from "@/models/constants/date.constant";
import { DetailContactDataDto } from "@/models/interface/contact-data";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { PurchaseOrderResponseDto } from "@/models/interface/purchase-order";
import { ReceivingItemDraftResponseDto } from "@/models/interface/receive-item";
import { FormValue } from "@/store/goodsReceiptChecklist.store";
import { AddressDataDto, ListContactDataDto } from "@interface/contact-data";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations } from "vuex";
import SelectAvailPo from "./SelectAvailPo.vue";

@Component({
  components: {
    SelectAvailPo,
    SelectSupplier,
    SelectBranch,
  },
  computed: {
    ...mapGetters({
      formModel: "goodsReceiptChecklistStore/getForm",
      detail: "goodsReceiptChecklistStore/getDetailGrDraft",
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "goodsReceiptChecklistStore/setForm",
      setPoProductType: "goodsReceiptChecklistStore/setPoProductType",
    }),
    ...mapActions({
      autofillFromPo: "goodsReceiptChecklistStore/autofillFromPo",
    }),
  },
})
export default class FormBasicInfo extends Vue {
  DATE_TIME_HOURS_DEFAULT_FORMAT = DATE_TIME_HOURS_DEFAULT_FORMAT;
  useLocalFilter = useLocalFilter;

  @Ref("form")
  form!: FormModel;

  detail!: ReceivingItemDraftResponseDto;
  formModel!: FormValue;
  setForm!: (payload: Partial<FormValue>) => void;
  setPoProductType!: (payload: string) => void;
  autofillFromPo!: (poId: string) => void;

  formRules = {
    purchaseOrderNumber: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    supplier: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    branchWarehouse: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    receiveDate: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    supplierDeliveryOrderNo: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    supplierBillToAddress: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    description: [
      {
        max: MAX_LENGTH_CHARACTER,
        message: this.$t("lbl_x_character_length_exceed_y", {
          x: this.$t("lbl_note"),
          y: MAX_LENGTH_CHARACTER,
        }),
      },
    ],
  };
  billAddressOptions: Array<Option<AddressDataDto>> = [];
  poDate: Moment | null = null;

  onChangePo(opt: Option<PurchaseOrderResponseDto> | undefined): void {
    this.poDate = null;
    this.setForm({
      purchaseOrderId: opt?.value || "",
      purchaseOrderNumber: opt?.label,
    });
    if (opt && opt.meta) {
      this.poDate = moment(opt.meta.date);
      this.autofillFromPo(opt.meta.id);
      this.getDetailContact(opt.meta.supplierId, ({ addressDataList }) => {
        this.setBillAddress(addressDataList);
      });
    }
  }

  onChangeSupplier(opt: Option<ListContactDataDto> | undefined): void {
    const address: AddressDataDto | undefined = opt?.meta?.addressDataList.find(
      e => e.billTo && e.primaryBillTo
    );
    const billAddress: string = address?.address || "";
    this.setForm({
      supplierBillToAddress: billAddress,
    });

    if (opt && opt.meta) {
      this.setBillAddress(opt.meta.addressDataList);
    }
  }

  onChangeBranch(e: Option<DataWarehouseBranch> | undefined): void {
    this.setForm({
      branchWarehouse: e?.label || "",
      branchWarehouseId: e?.value || "",
    });
  }

  setBillAddress(data: Array<AddressDataDto>): void {
    const { toBillToAddressOptions } = useContactData();
    this.billAddressOptions = toBillToAddressOptions(data);
  }

  getDetailContact(
    contactId: string,
    cb: (res: DetailContactDataDto) => void
  ): void {
    const { findOne } = useContactData();
    findOne(contactId).then(cb);
  }

  receiveDateRules(curr: Moment): boolean {
    if (!this.poDate) return useDisabledFromTomorrow(curr);
    return (
      curr.isBefore(this.poDate.startOf("day")) || useDisabledFromTomorrow(curr)
    );
  }
}
