var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        width: "50%",
        title: _vm.$tc("lbl_asset_item", _vm.modalAssetState.data.length)
      },
      on: { cancel: _vm.onCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.onCancel } },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_ok")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("a-table", {
        attrs: {
          "data-source": _vm.modalAssetState.data,
          columns: _vm.columns,
          "row-key": function(r, i) {
            return i
          },
          pagination: {
            showTotal: function(total) {
              return _vm.$t("lbl_total_items", { total: total })
            }
          },
          size: "small",
          scroll: { y: 700 }
        },
        scopedSlots: _vm._u([
          {
            key: "serialNumber",
            fn: function(text, row) {
              return [
                _c("a-input", {
                  attrs: { "allow-clear": "" },
                  model: {
                    value: row.serialNumber,
                    callback: function($$v) {
                      _vm.$set(row, "serialNumber", $$v)
                    },
                    expression: "row.serialNumber"
                  }
                })
              ]
            }
          },
          {
            key: "type",
            fn: function(text, row) {
              return [
                _c("a-input", {
                  attrs: { "allow-clear": "" },
                  model: {
                    value: row.type,
                    callback: function($$v) {
                      _vm.$set(row, "type", $$v)
                    },
                    expression: "row.type"
                  }
                })
              ]
            }
          },
          {
            key: "specification",
            fn: function(text, row) {
              return [
                _c("a-input", {
                  attrs: { "allow-clear": "" },
                  model: {
                    value: row.specification,
                    callback: function($$v) {
                      _vm.$set(row, "specification", $$v)
                    },
                    expression: "row.specification"
                  }
                })
              ]
            }
          },
          {
            key: "capacity",
            fn: function(text, row) {
              return [
                _c("a-input", {
                  attrs: { "allow-clear": "" },
                  model: {
                    value: row.capacity,
                    callback: function($$v) {
                      _vm.$set(row, "capacity", $$v)
                    },
                    expression: "row.capacity"
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }