







































































































































































































import FormContactCustomer from "@/components/ContactData/FormContactCustomer.vue";
import FormContactEmployee from "@/components/ContactData/FormContactEmployee.vue";
import FormContactSupplier from "@/components/ContactData/FormContactSupplier.vue";
import SelectBuyerType from "@/components/custom/select/SelectBuyerType.vue";
import SelectCountryCode from "@/components/custom/select/SelectCountryCode.vue";
import { useFindMasterTop } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { ContactFormState } from "@/store/models/Contact.model";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "FormContactGeneral",
  components: {
    FormContactCustomer,
    FormContactSupplier,
    FormContactEmployee,
    SelectCountryCode,
    SelectBuyerType,
  },
  computed: {
    ...mapState({
      formState: (st: any) =>
        st.contactStore.contactFormState as ContactFormState,
    }),
    buyerIdLabel(): string {
      if (this.formState.general.buyerType === "TIN") {
        return this.$t("lbl_tin").toString();
      } else if (this.formState.general.buyerType === "NIK") {
        return this.$t("lbl_nik").toString();
      } else if (this.formState.general.buyerType === "OTHER") {
        return this.$t("lbl_other").toString();
      } else if (this.formState.general.buyerType === "PASSPORT") {
        return this.$t("lbl_passport").toString();
      }
      return "";
    },
  },
  data() {
    return {
      contactTypeOptions: [
        {
          key: "customer",
          label: this.$t("lbl_customer"),
          value: "customer",
        },
        {
          key: "supplier",
          label: this.$t("lbl_supplier"),
          value: "supplier",
        },
        {
          key: "employee",
          label: this.$t("lbl_employee"),
          value: "employee",
        },
      ] as Option[],
      topOptions: [] as Option[],
      loading: {
        top: false,
      },
    };
  },
  mounted() {
    this.fetchTopList();
  },
  methods: {
    async fetchTopList() {
      try {
        this.loading.top = true;
        const response = await useFindMasterTop();
        this.topOptions = response.map(item => ({
          label: item.value,
          key: item.value,
          value: item.value,
        }));
      } finally {
        this.loading.top = false;
      }
    },
    handleBuyerTypeChange(): void {
      if (this.formState.general.buyerType === "TIN") {
        this.formState.general.buyerId = "";
      }
    },
  },
});
