

















































































































import { SearchBuilder } from "@/builder";
import { debounce } from "@/helpers/debounce";
import { useLocation, useProduct, useReceiveItem } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  ProductDetailDto,
  ProductResponseDto,
} from "@/models/interface/master-product";
import { ReceivingItemAssetRequestDto } from "@/models/interface/receive-item";
import {
  FormValue,
  ModalState,
  ReceivingItemRow,
} from "@/store/goodsReceiptChecklist.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";
import ModalAssetLine from "./ModalAssetLine.vue";
import SelectProductByType from "./SelectProductByType.vue";

@Component({
  components: {
    SelectProductByType,
    ModalAssetLine,
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    ...mapGetters({
      form: "goodsReceiptChecklistStore/getForm",
      poProductType: "goodsReceiptChecklistStore/getPoProductType",
      modalAssetState: "goodsReceiptChecklistStore/getModalAssetState",
    }),
  },
  methods: {
    ...mapActions({
      addRow: "goodsReceiptChecklistStore/addReceiveItemLine",
      deleteRow: "goodsReceiptChecklistStore/removeReceiveItemLine",
      toggleModalAsset: "goodsReceiptChecklistStore/toggleModalAsset",
    }),
  },
})
export default class FormTable extends Mixins(MNotificationVue) {
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;

  storeBaseDecimalPlace!: number;
  form!: FormValue;
  poProductType!: string;
  modalAssetState!: ModalState;
  addRow!: () => void;
  deleteRow!: (keys: Array<number>) => void;
  toggleModalAsset!: (payload: Array<ReceivingItemAssetRequestDto>) => void;

  locationOptions: Option[] = [];
  columns = [
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode", // bisa pilih kalau manual add row
      scopedSlots: { customRender: "productCode" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName", // bisa pilih kalau manual add row
      scopedSlots: { customRender: "productName" },
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "merk", // bisa pilih kalau manual add row
      width: "100px",
      scopedSlots: { customRender: "brand" },
    },
    {
      title: this.$t("lbl_po_qty"),
      dataIndex: "qtyPO", // statis
      width: "100px",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "productUom", // autofill dari product, statis
      width: "100px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_receipt_qty"),
      dataIndex: "qty", // input manual
      width: "100px",
      scopedSlots: { customRender: "receiptQty" },
    },
    {
      title: this.$t("lbl_receipt_location"),
      dataIndex: "locationReceived", // input manual
      scopedSlots: { customRender: "receiptLocation" },
    },
    {
      title: this.$t("lbl_track_as_asset"),
      dataIndex: "trackAsAsset", // input
      width: "150px",
      scopedSlots: { customRender: "trackAsAsset" },
    },
    {
      // ini buat nampilin additional data dalam bentuk modals
      // serial number, type, specification, capacity
      title: this.$t("lbl_notes"),
      scopedSlots: { customRender: "notes" },
    },
  ];
  selectedRowKeys: Array<number> = [];

  mounted() {
    this.getLocationList();
  }

  async fetchLocationList(
    params: RequestQueryParamsModel = new RequestQueryParams()
  ) {
    try {
      const { findAll, toOptions } = useLocation();
      const queries: string[] = [
        new SearchBuilder()
          .push([
            "warehouse.branchWarehouse.secureId",
            this.form.branchWarehouseId,
          ])
          .build(),
      ];
      if (params.search) {
        queries.unshift(params.search);
      }
      params.search = queries.join(SearchBuilder.AND);
      const response = await findAll(params);
      return toOptions(response.data, "id");
    } catch (error) {
      return [];
    }
  }

  async getLocationList() {
    try {
      this.locationOptions = await this.fetchLocationList();
    } catch (error) {
      this.locationOptions = [];
    }
  }

  onSearchLocation(record: ReceivingItemRow, search?: string) {
    record.isSearchLocation = true;
    const { searchBy } = useLocation();
    const query = searchBy({ name: search });
    const params = new RequestQueryParams();
    params.search = query;
    debounce(async () => {
      try {
        record.loadingLocation = true;
        record.locationOptions = await this.fetchLocationList(params);
      } catch {
        record.locationOptions = [];
      } finally {
        record.loadingLocation = false;
      }
    }, 500);
  }

  onSelectChange(keys: Array<number>): void {
    this.selectedRowKeys = keys;
  }

  onChangeProduct(
    e: Option<ProductResponseDto> | undefined,
    row: ReceivingItemRow
  ): void {
    row.productCode = e?.meta?.code || "";
    row.productName = e?.meta?.name || "";
    row.productId = e?.meta?.id || "";
    row.merk = e?.meta?.merk || "";
    row.productUom = "";
    row.productUomId = "";
    row.locationReceived = undefined;

    if (!row.productId) return;

    this.getDetailProduct(row.productId, detail => {
      const {
        baseUnit,
        baseUnitId,
        locationReceiveId = "",
        locationReceiveName = "",
      } = detail;
      row.productUom = baseUnit || "";
      row.productUomId = baseUnitId || "";
      if (locationReceiveId) {
        row.locationReceived = {
          label: locationReceiveName,
          key: locationReceiveId,
        };
      }
    });
  }

  getDetailProduct(id: string, cb: (res: ProductDetailDto) => void): void {
    const { findById } = useProduct();
    findById(id).then(cb);
  }

  handleDeleteRow(): void {
    this.showConfirmationDeleteItems(() => {
      this.deleteRow(this.selectedRowKeys);
      this.selectedRowKeys = [];
    });
  }

  viewItemAssets(row: ReceivingItemRow): void {
    const { initItemAssetDto } = useReceiveItem();
    const asset = initItemAssetDto();
    if (row.itemAssets.length === 0) {
      row.itemAssets.push(asset);
    }
    this.toggleModalAsset(row.itemAssets);
  }

  handleCloseModal(): void {
    const empty: Array<ReceivingItemAssetRequestDto> = [];
    this.toggleModalAsset(empty);
  }
}
